<template>
  <fw-layout
    :back-to="backToPath"
    :full="true"
    :wide="true"
    :loading="initialLoading"
    loading-title="Medicina do Trabalho S+"
  >
    <template #header-nav>
      <div class="flex gap-4 items-center">
        <div class="flex-shrink-0">
          <fw-heading size="lg">
            <span class="text-gray-500">{{ appointment.prefix }}</span>
            <span class="font-bold">{{ appointment.code }}</span>
          </fw-heading>
        </div>
        <div v-if="view !== 'reservation' && ['draft', 'ended'].includes(appointment.state)">
          <fw-tag size="sm" :type="appointmentStatusTagType[appointment.state]" custom-class="px-3">
            {{ $t(`appointmentStatus.${appointment.state}`) }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template #main-content>
      <fw-panel-box type="orange" class="px-2 py-3 my-3 font-medium mx-4">
        <div class="flex gap-3 text-sm">
          <div>
            <fw-icon-eye class="w-8 h-8 flex-shrink-0 opacity-80" />
          </div>
          <div class="flex flex-col gap-0">
            <div class="text-sm">
              As informações apresentadas nesta página são confidenciais e não deve partilhar este endereço com
              utilizadores não autorizados.
            </div>
            <div class="text-sm text-gray-500">
              O acesso a esta página irá expirar em
              {{ appointment.public_token_expiration_date | humanDateTimePT }}
            </div>
          </div>
        </div>
      </fw-panel-box>
      <PanelAppointmentSlotsPublic
        v-if="view == 'slots'"
        :appointment="appointment"
        :users="users"
        :loading="loading"
        :slots="slots"
        :people-validations="peopleValidations"
        :can-edit="validations.can_edit"
        @update-slot="updateSlot"
      ></PanelAppointmentSlotsPublic>
    </template>
  </fw-layout>
</template>

<script>
import PanelAppointmentSlotsPublic from '@/components/panels/PanelAppointmentSlotsPublic'

import { CALL_STATUS_TAG_COLORS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import groupBy from 'lodash/groupBy'

export default {
  components: {
    PanelAppointmentSlotsPublic,
  },

  data() {
    return {
      view: 'slots',
      appointment: {},
      users: {},
      applicants: [],
      usersPermissions: {},
      reservationUsers: {},
      reservations: {},
      reservation: {},
      validations: {
        can_delete: false,
        can_publish: false,
        can_edit: false,
      },
      reservationValidations: {},
      peopleValidations: {},
      loading: true,
      initialLoading: true,
      savingData: false,
      appointmentStatusTagType: CALL_STATUS_TAG_COLORS,
      managers: [],
      workers: [],
      viewers: [],
      slots: {},
      showResposibleSelectorModal: false,
      searchQuery: null,

      defaultAdditionalData: {
        tax_tier: null,
        accommodation_type: null,
        ies_code: null,
        ies: null,
        course: null,
        course_code: null,
        course_type: null,
      },
    }
  },

  computed: {
    currentReservationsPage() {
      return this.searchQuery?.page ?? 1
    },

    backToPath() {
      return `/`
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    appointmentKey() {
      return this.$route.params.key
    },

    reservationKey() {
      return this.$route.params.reservationKey
    },

    appointmentActions() {
      return [
        {
          canShowAction: this.validations.can_publish,
          actionName: 'publish',
          actionLabel: this.$t('publish'),
          addToCounter: true,
        },
        {
          canShowAction: this.validations.can_delete,
          customClass: 'text-red-400',
          actionName: 'delete',
          actionLabel: this.$t('delete'),
          addToCounter: false,
        },
        {
          canShowAction: this.validations.can_close,
          actionName: 'close',
          actionLabel: this.$t('close'),
          addToCounter: false,
        },
        {
          canShowAction: this.validations.can_reopen,
          actionName: 'reopen',
          actionLabel: this.$t('reopen'),
          addToCounter: false,
        },
      ]
    },

    // Checks
    checks() {
      return {
        isOpenToReservations: this.isOpenToReservations,
        isClosedToReservations: this.isClosedToReservations,
      }
    },

    hasAvailableActions() {
      return (
        this.validations.can_publish ||
        this.validations.can_delete ||
        this.validations.can_close ||
        this.validations.can_reopen
      )
    },

    isOpenToReservations() {
      return this.appointment.state == 'published'
    },

    isClosedToReservations() {
      return this.appointment.state != 'published'
    },

    token() {
      return this.$route.params.token
    },
  },

  watch: {
    view(newVal) {
      if (newVal) this.getCurrentViewData()
    },
  },

  mounted() {
    this.getCurrentViewData()
  },

  methods: {
    updateReservationManagers(response) {
      this.reservation.managers = response.managers
      this.reservation = response.reservation
      //save managers objects
      //reservationUsers

      // Force app to be updated (make sure actions appear on the sidebar)
      this.getReservation()
    },
    selectUserSearch(selection) {
      console.log('selection', selection)
    },

    openResponsibleSelectorModal() {
      console.log('hey > openResponsibleSelectorModal')
      this.showResposibleSelectorModal = true
    },

    closeModal() {
      this.showResposibleSelectorModal = false
    },

    async getCurrentViewData() {
      await this.getAppointmentSlots()
      /*if (initialLoad || !this.view || ['dashboard', 'metadata'].includes(this.view)) {
        console.log('initialLoad')
        await this.getAppointment(initialLoad)
        await this.getManagers()
      }*/
    },

    async getAppointment(initialLoad) {
      this.loading = true
      try {
        const response = await this.api.getAppointment(this.appointmentKey)
        console.log('getAppointment :>> ', response)
        this.appointment = {
          ...response,
          reservations_end_date: response.reservations_end_date
            ? Dates.buildCore(response.reservations_end_date).toDate()
            : null,
        }
        this.users = { ...this.users, ...response.users }
        console.log('response.validations', response.validations)
        if (response.validations) this.validations = response.validations

        // Set last appointment opened
        this.saveLocalLastAppointment()

        console.log('this.appointment :>> ', this.appointment)
      } catch (error) {
        console.log('Error getAppointment :>> ', error)
        this.handleErrors(error, 'Chamada não encontrada.')
      } finally {
        // Run just once
        if (initialLoad) {
          setTimeout(() => {
            this.initialLoading = false
          }, 750)
        }
      }
      this.loading = false
    },

    async getAppointmentSlots(silent = false) {
      if (!silent) this.loading = true
      try {
        let response = null
        if (this.token) {
          response = await this.api.getAppointmentsByToken(this.token)
        } else {
          response = await this.api.getAppointmentSlots(this.appointmentKey)
        }
        console.log('getAppointmentSlots :>> ', response)
        this.slots = groupBy(response.slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
        this.users = { ...this.users, ...response.users }
        if (response.appointment) this.appointment = response.appointment
      } catch (error) {
        this.handleErrors(error, 'Chamada não encontrada.')
      }
      this.loading = false
      this.initialLoading = false
    },

    pageChanged(page) {
      //Sidebar page change with the same query as the main search
      if (this.searchQuery == null) {
        this.searchQuery = {}
      }
      this.searchQuery.page = page
      this.getReservations(this.searchQuery)
    },

    async getReservations(query = null) {
      //save search query to be used in sidebar pageChanged
      this.searchQuery = query
      console.log('getReservations with query :>> ', query)
      this.loading = true

      try {
        const response = await this.api.getAppointmentReservations(this.appointmentKey, query)
        console.log('getReservations :>> ', response)
        this.appointment = {
          ...response.appointment,
          reservations_end_date: response.appointment.reservations_end_date
            ? Dates.buildCore(response.appointment.reservations_end_date).toDate()
            : null,
        }
        this.reservations = response.reservations
        this.users = { ...this.users, ...response.users }
        if (response.validations) this.validations = response.validations
      } catch (error) {
        console.log('Error getReservations :>> ', error)
        this.handleErrors(error, 'Chamada não encontrada.')
      }

      this.loading = false
    },

    async getReservation() {
      this.loading = true

      try {
        const response = await this.api.getReservation(this.appointmentKey, this.reservationKey)
        this.reservation = response.reservation
        this.appointment = {
          ...response.appointment,
          reservations_end_date: response.appointment.reservations_end_date
            ? Dates.buildCore(response.appointment.reservations_end_date).toDate()
            : null,
        }
        this.reservationValidations = response.validations
        this.users = { ...this.users, ...response.users }

        console.log('getReservation :>>', response)
      } catch (error) {
        console.log('Error getReservation :>> ', error)
      }

      this.loading = false
    },

    async getManagers() {
      this.loading = true

      try {
        const response = await this.api.getAppointmentManagers(this.appointmentKey)
        console.log('getAppointmentManagers :>> ', response)
        //this.appointment = response.appointment
        this.managers = response.permissions.manager ?? []
        this.workers = response.permissions.worker ?? []
        this.viewers = response.permissions.viewer ?? []
        this.usersPermissions = response.users
        if (response.validations) {
          this.peopleValidations = response.validations
        }
      } catch (error) {
        console.log('Error getAppointmentManagers :>> ', error)
        this.handleErrors(error, 'Chamada não encontrada.')
      }

      this.loading = false
    },

    async updateManagers(selection) {
      console.log('updateManagers with selection :>> ', selection)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateAppointmentManagers(this.appointmentKey, selection.usersAndRoles)
          console.log('updateAppointmentManagers :>> ', response)
          if (response.permissions.manager) this.managers = response.permissions.manager
          if (response.permissions.worker) this.workers = response.permissions.worker
          if (response.permissions.viewer) this.viewers = response.permissions.viewer
          if (response.users) this.usersPermissions = response.users
        },
        () => {
          this.savingData = false
        }
      )
    },

    async deleteManagers(selection) {
      console.log('deleteManagers with selection :>> ', selection)
      this.savingData = true

      utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteAppointmentManagers(this.appointmentKey, selection.usersAndRoles)
        console.log('deleteAppointmentManagers :>> ', response)
        if (response.permissions && response.permissions.manager) this.managers = response.permissions.manager
        if (response.permissions && response.permissions.worker) this.workers = response.permissions.worker
        if (response.permissions && response.permissions.viewer) this.viewers = response.permissions.viewer
        if (response.users) this.usersPermissions = response.users

        // Check for errors (if any) - even when response status is 200
        // (this is because the API returns 200 even when there are errors)
        // Must be fixed
        if (response.__errors__) {
          const errorKey = utils.errors(response).getKey()
          if (errorKey === 'Forbidden') {
            this.$buefy.snackbar.open({
              message:
                'Não foi possível remover o(a) utilizador(a) porque pode estar associado(a) a uma ou mais candidaturas.',
              type: 'is-danger',
            })
          } else {
            this.$buefy.snackbar.open({
              message: 'Ocorreu um erro não esperado ao tentar eliminar o utilizador.',
              type: 'is-danger',
            })
          }
        }
      })

      this.savingData = false
    },

    async updateAppointment(payload, withState = false) {
      console.log('updateAppointment with payload :>> ', payload)
      this.savingData = true

      if (!withState) {
        delete payload['state']
      }

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateAppointment(this.appointmentKey, payload)
          console.log('updateAppointment :>> ', response)
          this.appointment = {
            ...response,
            reservations_end_date: response.reservations_end_date
              ? Dates.buildCore(response.reservations_end_date).toDate()
              : null,
          }
          if (response.validations) this.validations = response.validations
          this.$buefy.snackbar.open({
            message: 'Metadados atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async getAppointmentApplicants(payload) {
      console.log('getAppointmentApplicants with payload :>> ', payload)
      this.loading = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getAppointmentApplicants(this.appointmentKey)
          console.log('getAppointmentApplicants :>> ', response)
          this.applicants = Object.values(response.applicants).flat()
        },
        () => {
          this.loading = false
        }
      )
    },

    async updateAppointmentApplicants(payload) {
      console.log('updateAppointmentApplicants with payload :>> ', payload)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateAppointmentApplicants(this.appointmentKey, payload)
          console.log('updateAppointment :>> ', response)
          this.applicants = Object.values(response.applicants).flat()

          this.$buefy.snackbar.open({
            message: 'Utilizadores atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async resendAppointmentApplicantsInvite(userKeys) {
      console.log('updateAppointmentApplicants with userKeys :>> ', userKeys)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.resendAppointmentApplicantsInvite(this.appointmentKey, userKeys)
          console.log('updateAppointment :>> ', response)
          this.applicants = Object.values(response.applicants).flat()
          this.$buefy.snackbar.open({
            message: 'Convocatórias enviadas com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async createAppointmentSlots(payload) {
      console.log('createAppointmentSlots with payload :>> ', payload)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.createAppointmentSlots(this.appointmentKey, payload)
          console.log('updateAppointment :>> ', response)
          this.slots = groupBy(response, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))

          this.$buefy.snackbar.open({
            message: 'Slots atualizados com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async deleteAppointmentSlots(payload) {
      console.log('deleteAppointmentSlots with payload :>> ', payload)
      if (!payload || !payload.length) return

      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.deleteAppointmentSlots(this.appointmentKey, { slot_keys: payload })
          console.log('updateAppointment :>> ', response)

          this.$buefy.snackbar.open({
            message: 'Slots removidos com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async updateSlot(data) {
      console.log('updateSlot with slotData :>> ', data)
      /*
 {
        key: reservation.key,
        present: reservation.present,
        message: message,
      }
      */

      this.savingData = true

      let payload = {}
      payload[data.key] = {
        status: data.present ? 'present' : 'absent',
        message: data.message ? data.message : '',
      }
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateAppointmentPublicSlotPresence(this.token, payload)
          console.log('updateAppointmentSlots :>> ', response)
          this.$buefy.snackbar.open({
            message: 'Presença marcada com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
          this.getAppointmentSlots(true)
        }
      )
    },

    confirmPublish() {
      if (!this.validations.can_publish) return
      this.$buefy.dialog.confirm({
        confirmText: 'Abrir',
        cancelText: 'Cancelar',
        title: 'Abrir chamada',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja continuar? Abrindo esta chamada os trabalhadores serão notificados.</div>`,
        onConfirm: () => {
          this.updateAppointment({ state: 'published' }, true)
        },
      })
    },

    confirmDelete() {
      if (!this.validations.can_delete) return
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        title: 'Eliminar chamada',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>Eliminar</strong> esta chamada? Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteAppointment()
        },
      })
    },

    confirmClose() {
      if (!this.validations.can_close) return
      this.$buefy.dialog.confirm({
        confirmText: 'Fechar',
        cancelText: 'Cancelar',
        title: 'Fechar agendamentos',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>Fechar</strong> esta chamada?`,
        onConfirm: () => {
          this.updateAppointment({ state: 'closed' }, true)
        },
      })
    },

    confirmReopen() {
      if (!this.validations.can_reopen) return
      this.$buefy.dialog.confirm({
        confirmText: 'Reabrir',
        cancelText: 'Cancelar',
        title: 'Reabrir agendamentos',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>Reabrir</strong> esta chamada?`,
        onConfirm: () => {
          this.updateAppointment({ state: 'published' }, true)
        },
      })
    },

    async deleteAppointment() {
      if (!this.validations.can_delete) return
      try {
        await this.api.deleteAppointment(this.appointmentKey)
        utils.sleep(250)
        this.$buefy.snackbar.open({
          message: `Chamada eliminada com sucesso.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 2000,
          queue: true,
        })
        this.loading = false
        this.$router.push({ name: 'manage-appointments' })
      } catch (error) {
        console.error('deleteAppointment :>>', error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao eliminar a chamada.',
          type: 'is-danger',
        })
      }
    },

    addedMessage(response) {
      this.reservation.state = response.reservation.state
    },

    updatedReservation(response) {
      console.log('updatedReservation :>> ', response)
      this.reservation = response.reservation
      this.reservationValidations = response.validations

      let reservationSidebar = this.reservations.reservations.find(el => el.key == response.reservation.key)
      console.log('reservationSidebar', reservationSidebar)
      if (reservationSidebar) {
        reservationSidebar.state = response.reservation.state
        reservationSidebar.has_updates = false
      }
    },

    handleErrors(error, notFoundMessage) {
      const errorKey = utils.errors(error).getKey()
      if (errorKey && errorKey == 'Forbidden') {
        return this.$router.push({ name: 'forbidden' })
      }
      if (errorKey && errorKey == 'appointmentCodeAlreadyExists') {
        this.$buefy.dialog.alert({
          title: this.$t('appointmentCodeAlreadyExists.title'),
          message: this.$t('appointmentCodeAlreadyExists.message'),
          type: 'is-danger',
        })

        return
      } else if (errorKey && errorKey == 'NotFound') {
        this.$buefy.snackbar.open({
          message: notFoundMessage,
          type: 'is-danger',
        })
        return this.$router.push({ name: 'appointments' })
      }
    },

    // Utils
    saveLocalLastAppointment() {
      const appointment = {
        code: this.appointment.code,
        key: this.appointment.key,
        prefix: this.appointment.prefix,
      }
      localStorage.setItem('last-appointment-open', JSON.stringify(appointment))
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "appointmentCodeAlreadyExists": {
      "title": "Código já existe",
      "message": "Já existe uma chamada com o código que tentou definir. Por favor, indique outro para guardar as alterações."
    },
    "close": "Encerrar",
    "reopen": "Reabrir",
    "status": "Estado",
    "publish": "Publicar",
    "delete": "Eliminar",
    "appointmentStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "deleted": "Removido",
      "closed": "Fechado"
    }
  },
  "en": {
    "appointmentCodeAlreadyExists": {
      "title": "Code already exists",
      "message": "There is already a row with the code you tried to set. Please enter another one to save your changes."
    },
    "publish": "Publish",
    "delete": "Delete",
    "status": "Status",
    "close": "Encerrar",
    "reopen": "Reabrir",
    "appointmentStatus": {
      "draft": "Draft",
      "published": "Published",
      "deleted": "Deleted",
      "closed": "Closed"
    }
  }
}
</i18n>
