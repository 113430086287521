<template>
  <div v-if="appointment" class="relative">
    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="'Horários'" featured class="m-5">
        <template #toolbar>
          <b-dropdown v-if="!slotsAreEmpty" aria-role="list" position="is-bottom-left">
            <fw-button-dropdown
              slot="trigger"
              aria-role="listitem"
              type="xlight"
              :label="filters[selectedFilter].label"
              class="font-medium text-gray-500"
            >
            </fw-button-dropdown>
            <b-dropdown-item
              v-for="(value, key) in filters"
              :key="key"
              :label="value.label"
              aria-role="listitem"
              :class="`font-medium ${key == selectedFilter ? 'text-primary' : 'text-gray-500'}`"
              @click.native="selectFilter(key)"
            >
              {{ value.label }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <div v-if="!filteredSlotsAreEmpty" class="grid grid-cols-1 gap-3 bg-white rounded-xl px-4 py-2">
          <table class="public-slots-table">
            <tr class="text-sm">
              <th>Código</th>
              <th>Dia</th>
              <th>Horário</th>
              <th>Nome funcionário</th>
              <th>Número</th>
              <th>E-mail</th>
              <th>Presença</th>
            </tr>
            <template v-for="(daySlots, day) in filteredSlots">
              <template v-for="(slot, slotI) in daySlots">
                <template v-if="slot.reservations && slot.reservations.length">
                  <tr
                    v-for="(reservation, r) in slot.reservations"
                    :key="reservation.key"
                    :class="{ present: reservation.present === true, 'not-present': reservation.present === false }"
                  >
                    <td>{{ appointment.code }}</td>
                    <td>{{ day | formatDate }}</td>
                    <td>{{ slot.start_datetime | formatTime }} - {{ slot.end_datetime | formatTime }}</td>
                    <td>
                      {{
                        users && users[reservation.user_key]
                          ? users[reservation.user_key].full_name
                          : reservation.user_key
                      }}
                    </td>
                    <td>
                      {{
                        users && users[reservation.user_key]
                          ? users[reservation.user_key].number
                          : 'Número não definido'
                      }}
                    </td>
                    <td>
                      {{
                        users && users[reservation.user_key] ? users[reservation.user_key].email : 'E-mail não definido'
                      }}
                    </td>
                    <td>
                      <span :class="{ 'text-gray-400': reservation.present == null }">
                        {{
                          reservation.present == null ? 'Não definida' : reservation.present ? 'Presente' : 'Ausente'
                        }}
                      </span>
                    </td>
                    <td class="flex gap-1 justify-end">
                      <fw-button
                        type="xlight"
                        @click.native="messsageEdit(day, slotI, r, reservation.medical_observations)"
                      >
                        <fw-icon-message
                          class="h-5 w-5"
                          :class="{
                            'text-primary':
                              reservation.medical_observations && reservation.medical_observations.length > 0,
                          }"
                        />
                        <fw-dot
                          v-if="reservation.medical_observations && reservation.medical_observations.length > 0"
                          :ping="false"
                        />
                      </fw-button>
                      <fw-menu-more>
                        <b-dropdown-item
                          aria-role="menuitem"
                          @click="messsageEdit(day, slotI, r, reservation.medical_observations)"
                        >
                          Editar comentário
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="reservation.present === null || reservation.present === false"
                          aria-role="menuitem"
                          @click="setPresence(day, slot, r, reservation.medical_observations, 'present')"
                          >Marcar como presente</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="reservation.present === null || reservation.present === true"
                          aria-role="menuitem"
                          @click="setPresence(day, slot, r, reservation.medical_observations, 'not-present')"
                          >Marcar como ausente</b-dropdown-item
                        >
                      </fw-menu-more>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="day + slotI">
                    <td>{{ appointment.code }}</td>
                    <td>{{ day | formatDate }}</td>
                    <td>{{ slot.start_datetime | formatTime }} - {{ slot.end_datetime | formatTime }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
              </template>
            </template>
          </table>
        </div>
        <fw-panel-info v-else clean>Ainda não foram definidos slots para esta chamada.</fw-panel-info>
      </fw-panel>
    </div>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ appointment, timeSlots, slots, selectedSlot, peopleValidations, isWorker }"></json-viewer>
    </fw-panel-info>

    <fw-modal :active.sync="isActiveModal" boxed="sm" size="3xl" width="42rem" confirm-close @close="closeModal">
      <ModalMessage
        v-if="isActiveModal"
        :message="messageEditing"
        :instructions="
          'Insira um comentário que será partilhada com o serviço SASUC e entre médicos que tenham acesso a esta tabela. NÃO INTRODUZA DADOS CLÍNICOS OU PESSOAIS sensíveis.'
        "
        :title="'Comentário partilhado'"
        :force-private-message="false"
        :show-public-private-toggle="false"
        :ignore-confirm-public-message="false"
        :required-description="false"
        :allow-files="false"
        @close="closeModal"
        @save="saveMessage"
      ></ModalMessage>
    </fw-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { required, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { CALL_TYPES } from '@/utils/index.js'
import groupBy from 'lodash/groupBy'
import ModalMessage from '@/fw-modules/fw-core-vue/ui/components/modals/ModalMessage'

export default {
  name: 'PanelAppointmentSlotsPublic',

  components: {
    LoadingPlaceholder,
    ModalMessage,
  },

  props: {
    appointment: {
      type: Object,
      default: () => {
        return {}
      },
    },

    slots: {
      type: Object,
      default: () => {},
    },

    users: {
      type: Object,
      default: () => {},
    },

    peopleValidations: {
      type: Object,
      default: () => {},
    },

    canEdit: {
      type: Boolean,
      default: false,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActiveEditSlotsModal: false,
      isActiveSlotsModal: false,
      timeSlots: {},
      selectedSlot: null,
      isModalActive: false,
      appointmentTypes: CALL_TYPES,
      minEndDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      selectedFilter: 'all',
      filters: {
        all: {
          label: 'Todos',
        },
        noReservations: {
          label: 'Sem agendamentos',
        },
        withReservations: {
          label: 'Com agendamentos',
        },
      },
      messageEditing: null,
    }
  },

  computed: {
    isActiveModal() {
      return this.selectedSlot != null
    },
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language
    },

    user() {
      return this.$store.getters.getUser
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    langs() {
      return [this.language, this.secondaryLanguage]
    },

    minDate() {
      return this.appointment && this.appointment.state === 'published' ? null : new Date()
    },

    timeSlotsAreEmpty() {
      return utils.isObjectEmpty(this.timeSlots)
    },

    filteredSlotsAreEmpty() {
      return utils.isObjectEmpty(this.filteredSlots)
    },

    slotsAreEmpty() {
      return utils.isObjectEmpty(this.slots)
    },

    isWorker() {
      return (
        this.peopleValidations && !this.peopleValidations.can_edit_managers && !this.peopleValidations.can_edit_workers
      )
    },

    filteredSlots() {
      if (this.selectedFilter === 'noReservations') {
        const slots = Object.values(this.timeSlots)
          .flat()
          .filter(el => !el.reservations?.length)

        return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
      }

      if (this.selectedFilter === 'withReservations') {
        const slots = Object.values(this.timeSlots)
          .flat()
          .filter(el => el.reservations?.length)

        return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
      }

      return this.timeSlots
    },
  },

  watch: {
    slots(newVal) {
      this.timeSlots = newVal
    },
  },

  mounted() {
    this.timeSlots = this.slots
  },

  validations() {
    return {
      appointment: {
        start_date: { required, min: minValue(this.minDate) },
        end_date: { required, min: minValue(this.start_date || this.minDate) },
      },
    }
  },

  methods: {
    saveMessage(message) {
      console.log('saveMessage', message)
      const reservation = this.timeSlots[this.selectedSlot.day][this.selectedSlot.slot].reservations[
        this.selectedSlot.r
      ]
      this.$emit('update-slot', {
        key: reservation.key,
        present: reservation.present,
        message: message.description,
      })
      this.closeModal()
    },
    closeModal() {
      this.selectedSlot = null
    },
    messsageEdit(day, slot, r, message) {
      this.selectedSlot = { day, slot, r }
      console.log({ day, slot, r, message })
      this.messageEditing = {
        description: message !== null ? message : '',
      }
    },
    setPresence(day, slot, r, message, action) {
      console.log({ day, slot, r })
      const reservation = slot.reservations[r]
      reservation.present = action === 'present' ? true : false
      this.$emit('update-slot', {
        key: reservation.key,
        present: reservation.present,
        message: message,
      })
    },
    saveSlots() {
      this.$emit('save-slots', { slots: Object.values(this.timeSlots).flat() })
    },

    selectFilter(key) {
      this.selectedFilter = key
    },

    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },

    createTimeSlots(params) {
      const { startTime, endTime, startDate, endDate, timeInterval, availability } = params

      let startingDate = Dates.buildCore(startDate).startOf('hour') // now, but with 0 mins, 0 secs, and 0 ms
      const endingDate = Dates.buildCore(endDate).endOf('hour')
      const timeStops = {}

      let startTimeSlot = undefined
      let endTimeSlot = undefined
      const dates = Dates.getDatesFromRange(startingDate, endingDate)

      for (const date of dates) {
        startTimeSlot = Dates.buildCore(date)
          .set('hour', startTime.getHours())
          .set('minute', startTime.getMinutes())
          .set('second', 0)

        endTimeSlot = Dates.buildCore(date)
          .set('hour', endTime.getHours())
          .set('minute', endTime.getMinutes())
          .set('second', 0)

        const currentWeekDay = startTimeSlot.day()
        if (currentWeekDay == 0 || currentWeekDay == 6) continue

        while (startTimeSlot.isBefore(endTimeSlot, 'minute') || startTimeSlot.isSame(endTimeSlot, 'minute')) {
          let newTime = startTimeSlot.add(timeInterval, 'minute')
          if (newTime.isAfter(endTimeSlot, 'minute')) break
          if (timeStops[date])
            timeStops[date].push({
              start_datetime: startTimeSlot,
              end_datetime: newTime,
              availability,
              can_delete: true,
            })
          else
            timeStops[date] = [{ start_datetime: startTimeSlot, end_datetime: newTime, availability, can_delete: true }]
          startTimeSlot = newTime
        }
      }

      this.timeSlots = timeStops
    },

    editSlot(slot, slotDate, slotI) {
      console.log({ slotDate, slotI })
      this.selectedSlot = { ...slot, slotDate, slotI }
      this.isActiveEditSlotsModal = true
    },

    updateSlot(availability) {
      console.log('this.selectedSlot', this.selectedSlot)
      if (this.selectedSlot.key) this.$emit('update-slot', { key: this.selectedSlot.key, availability })

      const slot = this.timeSlots[this.selectedSlot.slotDate][this.selectedSlot.slotI]
      if (slot) slot.availability = availability
      this.isActiveEditSlotsModal = false
      this.selectedSlot = null
    },

    deleteSlot(slotDate, slotIndex = -1) {
      if (slotIndex > -1) {
        const slot = this.timeSlots[slotDate][slotIndex]
        console.log('slot', slot)
        this.$emit('delete-slots', slot?.key ? [slot.key] : undefined)
        this.timeSlots[slotDate].splice(slotIndex, 1)
      } else {
        this.$emit(
          'delete-slots',
          this.timeSlots[slotDate].filter(el => el.key).map(el => el.key)
        )
        this.$delete(this.timeSlots, slotDate)
      }
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "appointment": "Metadados",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "appointmentCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "today": "Hoje",
    "yesterday": "Ontem",
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    },
    "notDefined": "Não definido"
  },
  "en": {
    "generalDetails": "General details",
    "appointmentCode": "Procedure code",
    "appointment": "Metadata",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "today": "Today",
    "yesterday": "Yesterday",
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    },
    "notDefined": "Not defined"
  }
}
</i18n>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table tr {
  border-bottom: 1px solid #f2f2f2;
}
table th {
  text-align: left;
  font-weight: 500;
  @apply text-gray-500 py-5;
}
table td {
  vertical-align: middle;
  @apply text-gray-800 py-5;
}
table tr.present td {
  @apply text-primary font-semibold;
}
table tr.not-present td {
  @apply text-gray-500;
}
</style>
